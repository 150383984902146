import "video-react/dist/video-react.css";
import "./VideoInterview.scss";

import {
  BoxTitle,
  Button,
  ErrorHandler,
  Icon,
  Toast,
  VideoInternal,
  VideoUser,
  Link,
  GroupsVideoInterview,
  VideoInterviewRatingsList,
  VideoInterviewCommentsList,
} from "../../index";
import React, { Component } from "react";
import { get as deepGet } from "lodash";
import { get, load, post } from "../../../store/modules/VideoAnalysis";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import { parse } from "../../../utils/QueryFunctions";
import propTypes from "prop-types";
import { toast } from "react-toastify";
import { withRouter } from "react-router";

const mapStateToProps = (state) => ({
  analysisContent: state.VideoAnalysis.data,
  analysisVideo: state.VideoAnalysis.analysis,
  loading: state.VideoAnalysis.loading,
  loaded: state.VideoAnalysis.loaded,
  error: state.VideoAnalysis.error,
  isPostingComment: deepGet(
    state.VideoAnalysis,
    "post.post_comment.loading",
    false
  ),
});

class VideoMain extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      comment: "",
      source: "",
      video: "",
      videoMask: "",
      activeMask: false,
      rating: 0,
      loaded: false,
      comunicacao: 0,
      cuidado_video: 0,
      postura: 0,
      aderencia: 0,
      currentQuestion: 0,
      average_rating: null,
      ratings: [],
      comments: [],
    };

    this.handlePostComment = this.handlePostComment.bind(this);
    this.handleUpdateText = this.handleUpdateText.bind(this);
    this.requestContent = this.requestContent.bind(this);
    this.handleStars = this.handleStars.bind(this);
    this.getRatings = this.getRatings.bind(this);
    this.getComments = this.getComments.bind(this);
    this.handleRating = this.handleRating.bind(this);
    this.showNotifications = this.showNotifications.bind(this);
    this.getAnalysis = this.getAnalysis.bind(this);
    this.changeQuestion = this.changeQuestion.bind(this);

    this.requestContent();
  }

  // Método utilizado para exibir os feedbacks dos usuários
  showNotifications(message, type = "success") {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  }

  requestContent() {
    const query = parse(window.location.search);
    const { load, visitor, tokenShare } = this.props;
    const token = visitor ? "compartilhamento_token" : "access_token";

    load(
      query.video_entrevista,
      query.candidato_id,
      query.vaga_id,
      token,
      tokenShare
    ).then((response) => {
      this.getRatings(response);
      this.getComments(response);
      this.getAnalysis(response);
    });
  }

  getRatings(response) {
    const { get, visitor } = this.props;
    const token = visitor ? "compartilhamento_token" : "access_token";

    if (
      !response?.video_entrevista?.id ||
      !response?.participante?.candidato_id ||
      !response?.processo_seletivo?.id
    ) {
      return;
    }

    let options = [
      `video_entrevista_id=${response.video_entrevista.id}`,
      `candidato_id=${response.participante.candidato_id}`,
      `processo_seletivo_id=${response.processo_seletivo.id}`,
    ];

    this.props.visitor &&
      options.push(`convidado_token=${this.props.tokenShare}`);

    get(
      `video-entrevistas/participantes/avaliacoes?${options.join("&")}`,
      "stars",
      token
    ).then((data) => {
      if (!data) {
        this.setState({
          rating: 0,
          comunicacao: 0,
          cuidado_video: 0,
          postura: 0,
          aderencia: 0,
          ratings: [],
          average_rating: null,
        });
      } else {
        this.setState({
          rating: data.avaliacao_principal
            ? data.avaliacao_principal.pontuacao
            : 0,
          comunicacao: data.avaliacao_principal
            ? data.avaliacao_principal.comunicacao
            : 0,
          cuidado_video: data.avaliacao_principal
            ? data.avaliacao_principal.cuidado_video
            : 0,
          postura: data.avaliacao_principal
            ? data.avaliacao_principal.postura
            : 0,
          aderencia: data.avaliacao_principal
            ? data.avaliacao_principal.aderencia
            : 0,
          ratings: data.avaliacoes,
          average_rating: data.media,
        });
      }
    });
  }

  getComments(response) {
    const { get, visitor, tokenShare } = this.props;
    const token = visitor ? "compartilhamento_token" : "access_token";

    let options = [`participante_id=${response.participante.id}`];

    visitor && options.push(`convidado_token=${tokenShare}`);
    get(
      `video-entrevistas/participantes/comentarios?${options.join("&")}`,
      "comments",
      token
    ).then((data) => {
      if (data) {
        this.setState({
          comments: data.comentarios,
        });
      } else {
        this.setState({
          comments: [],
        });
      }
    });
  }

  getAnalysis(response) {
    const { get, visitor, analysisContent } = this.props;
    const token = visitor ? "compartilhamento_token" : "access_token";

    const data = response || analysisContent;

    if (data?.participante && data.participante.respostas.length) {
      let options = [];
      visitor && options.push(`convidado_token=${this.props.tokenShare}`);

      get(
        `video-entrevistas/${
          data.participante.video_entrevista_id
        }/participantes/${data.participante.id}/respostas/${
          data.participante.respostas[this.state.currentQuestion].id
        }/analise-video?${options.join("&")}`,
        "analysis",
        token
      );
    }
  }

  changeQuestion(type, index = 0) {
    type === "number"
      ? this.setState({ currentQuestion: index }, () => this.getAnalysis())
      : type === "prev"
      ? this.setState(
          (prevState) => ({
            currentQuestion: prevState.currentQuestion - 1,
          }),
          () => this.getAnalysis()
        )
      : this.setState(
          (prevState) => ({
            currentQuestion: prevState.currentQuestion + 1,
          }),
          () => this.getAnalysis()
        );
  }

  handlePostComment() {
    const { post, analysisContent, visitor, tokenShare } = this.props;
    const token_convidado = visitor ? tokenShare : null;
    const token = visitor ? "compartilhamento_token" : "access_token";

    post(
      "video-entrevistas/participantes/comentarios",
      {
        participante_id: analysisContent.participante.id,
        comentario: this.state.comment,
        convidado_token: token_convidado,
      },
      "post_comment",
      token
    ).then(
      () => {
        this.setState({ comment: "" });
        this.getComments(analysisContent);
      },
      () => {
        this.setState({ comment: "" });
        this.showNotifications(
          intl.getHTML(
            "VIDEO_ENTREVISTA.EMPRESA.VIDEO.MENSAGENS.COMENTARIO_ERRO"
          ),
          "error"
        );
      }
    );
  }

  handleStars(e) {
    const { post, analysisContent, visitor, tokenShare } = this.props;
    const targetValue = e.target.value;
    const token_convidado = visitor ? tokenShare : null;
    const token = visitor ? "compartilhamento_token" : "access_token";

    this.setState(
      {
        rating: parseInt(targetValue),
      },
      () => {
        post(
          "video-entrevistas/participantes/avaliacoes",
          {
            video_entrevista_id:
              analysisContent.participante.video_entrevista_id,
            candidato_id: analysisContent.participante.candidato_id,
            processo_seletivo_id: analysisContent.processo_seletivo.id,
            pontuacao: targetValue,
            convidado_token: token_convidado,
          },
          "post-stars",
          token
        ).then(
          () => {
            this.showNotifications(
              intl.getHTML("VIDEO_ENTREVISTA.EMPRESA.VIDEO.MENSAGENS.AVALIACAO")
            );
            this.getRatings(analysisContent);
          },
          () => {
            this.showNotifications(
              intl.getHTML(
                "VIDEO_ENTREVISTA.EMPRESA.VIDEO.MENSAGENS.AVALIACAO_ERRO"
              ),
              "error"
            );
          }
        );
      }
    );
  }

  handleRating(e, type) {
    const { post, analysisContent, visitor, tokenShare } = this.props;
    const targetValue = e.target.value;
    const token_convidado = visitor ? tokenShare : null;
    const token = visitor ? "compartilhamento_token" : "access_token";

    this.setState(
      {
        [type]: parseInt(targetValue),
      },
      () => {
        post(
          "video-entrevistas/participantes/avaliacoes",
          {
            video_entrevista_id:
              analysisContent.participante.video_entrevista_id,
            candidato_id: analysisContent.participante.candidato_id,
            processo_seletivo_id: analysisContent.processo_seletivo.id,
            [type]: targetValue,
            token: token_convidado,
          },
          type,
          token
        ).then(
          () => {
            this.showNotifications(
              intl.getHTML(
                `VIDEO_ENTREVISTA.EMPRESA.VIDEO.MENSAGENS.${intlReplace(type)}`
              )
            );
          },
          () => {
            this.showNotifications(
              intl.getHTML(
                `VIDEO_ENTREVISTA.EMPRESA.VIDEO.MENSAGENS.${intlReplace(
                  type
                )}_ERRO`
              ),
              "error"
            );
          }
        );
      }
    );
  }

  handleUpdateText(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  render() {
    const {
      loaded,
      error,
      analysisContent,
      analysisVideo,
      isPostingComment,
      visitor,
      tokenShare,
      location,
    } = this.props;
    const {
      rating,
      comunicacao,
      postura,
      cuidado_video,
      aderencia,
      currentQuestion,
      ratings,
      average_rating,
      comments,
    } = this.state;

    const param = location.search
      .replace("?", "")
      .split("&")
      .reduce((acc, item) => {
        const [key, value] = item.split("=");
        acc[key] = value;
        return acc;
      }, {});

    const paramUrlVisitor = deepGet(
      param,
      "urlVisitor",
      tokenShare ? true : false
    );
    const linkReturnList =
      loaded && !JSON.parse(paramUrlVisitor)
        ? `/video-entrevista/${analysisContent.processo_seletivo.id}`
        : `/convidado/assiste/video-entrevista/${tokenShare}`;
    if (loaded && !analysisContent.participante.respostas.length) {
      return (
        <div>
          <div className="vg-title-loader">
            <Icon iconKey="titleLoader" />
          </div>
          <div className="vg-container">
            <ErrorHandler
              text={intl.get("GRAPHS.ERROR_MESSAGE")}
              error={error}
              retryHandler={this.requestContent}
            />
          </div>
        </div>
      );
    }

    return loaded ? (
      <div className="vg-video-page">
        <BoxTitle
          title={intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.TITULO")}
          text={intl.getHTML("VIDEO_ENTREVISTA.EMPRESA.VIDEO.TEXTO", {
            job: analysisContent.processo_seletivo.cargo,
            vaga_id: analysisContent.processo_seletivo.id,
          })}
          inlineLink={{
            text: intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.LINK_TITULO"),
            to: linkReturnList,
          }}
          secondLevel
        />
        <div className="vg-container vg-video-header">
          <div className="vg-row middle-xs">
            <div className="vg-col-xs-12 vg-col-md-3">
              <VideoUser
                analysisContent={analysisContent}
                analysisVideo={analysisVideo}
              />
            </div>
            <div className="vg-col-xs-12 vg-col-md-6 vg-text-center">
              {!visitor && (
                <GroupsVideoInterview
                  processo_seletivo_id={analysisContent.processo_seletivo.id}
                  candidato_id={analysisContent.participante.candidato_id}
                  video_entrevista_id={analysisContent.video_entrevista.id}
                />
              )}
              <div className="vg-dropdown-rating">
                <div className="vg-inline-block">
                  <h3 className="vg-text-center vg-text-avaliation">
                    {intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.AVALIACAO_GERAL")}
                  </h3>
                  <div className="vg-stars-container">
                    <div className="vg-rating-star">
                      <input
                        type="radio"
                        id="rate-5"
                        name="rate"
                        checked={rating == 5}
                        value="5"
                        onChange={this.handleStars}
                      />
                      <label htmlFor="rate-5">
                        <Icon iconKey="star" />
                      </label>
                      <input
                        type="radio"
                        id="rate-4"
                        name="rate"
                        checked={rating == 4}
                        value="4"
                        onChange={this.handleStars}
                      />
                      <label htmlFor="rate-4">
                        <Icon iconKey="star" />
                      </label>
                      <input
                        type="radio"
                        id="rate-3"
                        name="rate"
                        checked={rating == 3}
                        value="3"
                        onChange={this.handleStars}
                      />
                      <label htmlFor="rate-3">
                        <Icon iconKey="star" />
                      </label>
                      <input
                        type="radio"
                        id="rate-2"
                        name="rate"
                        checked={rating == 2}
                        value="2"
                        onChange={this.handleStars}
                      />
                      <label htmlFor="rate-2">
                        <Icon iconKey="star" />
                      </label>
                      <input
                        type="radio"
                        id="rate-1"
                        name="rate"
                        checked={rating == 1}
                        value="1"
                        onChange={this.handleStars}
                      />
                      <label htmlFor="rate-1">
                        <Icon iconKey="star" />
                      </label>
                    </div>
                  </div>
                </div>
                {!visitor && (
                  <div
                    className={`vg-dropdown-rating-content ${
                      rating > 0 ? "vg-active" : ""
                    }`}
                  >
                    <h4>
                      {intl.get(
                        "VIDEO_ENTREVISTA.EMPRESA.VIDEO.LEGENDA.COMUNICACAO"
                      )}
                    </h4>
                    <div className="vg-time-reading">
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="1"
                            name="comunicacao"
                            onChange={(e) =>
                              this.handleRating(e, "comunicacao")
                            }
                            checked={comunicacao == 1}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.1"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="2"
                            name="comunicacao"
                            onChange={(e) =>
                              this.handleRating(e, "comunicacao")
                            }
                            checked={comunicacao == 2}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.2"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="3"
                            name="comunicacao"
                            onChange={(e) =>
                              this.handleRating(e, "comunicacao")
                            }
                            checked={comunicacao == 3}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.3"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="4"
                            name="comunicacao"
                            onChange={(e) =>
                              this.handleRating(e, "comunicacao")
                            }
                            checked={comunicacao == 4}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.4"
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                    <h4>
                      {intl.get(
                        "VIDEO_ENTREVISTA.EMPRESA.VIDEO.LEGENDA.POSTURA"
                      )}
                    </h4>
                    <div className="vg-time-reading">
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="1"
                            name="postura"
                            onChange={(e) => this.handleRating(e, "postura")}
                            checked={postura == 1}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.1"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="2"
                            name="postura"
                            onChange={(e) => this.handleRating(e, "postura")}
                            checked={postura == 2}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.2"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="3"
                            name="postura"
                            onChange={(e) => this.handleRating(e, "postura")}
                            checked={postura == 3}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.3"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="4"
                            name="postura"
                            onChange={(e) => this.handleRating(e, "postura")}
                            checked={postura == 4}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.4"
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                    <h4>
                      {intl.get(
                        "VIDEO_ENTREVISTA.EMPRESA.VIDEO.LEGENDA.ADERENCIA"
                      )}
                    </h4>
                    <div className="vg-time-reading">
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="1"
                            name="aderencia"
                            onChange={(e) => this.handleRating(e, "aderencia")}
                            checked={aderencia == 1}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.1"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="2"
                            name="aderencia"
                            onChange={(e) => this.handleRating(e, "aderencia")}
                            checked={aderencia == 2}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.2"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="3"
                            name="aderencia"
                            onChange={(e) => this.handleRating(e, "aderencia")}
                            checked={aderencia == 3}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.3"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="4"
                            name="aderencia"
                            onChange={(e) => this.handleRating(e, "aderencia")}
                            checked={aderencia == 4}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.4"
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                    <h4>
                      {intl.get(
                        "VIDEO_ENTREVISTA.EMPRESA.VIDEO.LEGENDA.CUIDADO_VIDEO"
                      )}
                    </h4>
                    <div className="vg-time-reading">
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="1"
                            name="cuidado_video"
                            onChange={(e) =>
                              this.handleRating(e, "cuidado_video")
                            }
                            checked={cuidado_video == 1}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.1"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="2"
                            name="cuidado_video"
                            onChange={(e) =>
                              this.handleRating(e, "cuidado_video")
                            }
                            checked={cuidado_video == 2}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.2"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="3"
                            name="cuidado_video"
                            onChange={(e) =>
                              this.handleRating(e, "cuidado_video")
                            }
                            checked={cuidado_video == 3}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.3"
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="vg-custom-radio">
                        <label className="vg-label">
                          <input
                            type="radio"
                            value="4"
                            name="cuidado_video"
                            onChange={(e) =>
                              this.handleRating(e, "cuidado_video")
                            }
                            checked={cuidado_video == 4}
                          />
                          <span className="vg-label-text">
                            {intl.get(
                              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANALISE.4"
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="vg-col-xs-12 vg-col-md-3">
              <div className="vg-curriculo">
                {!visitor && (
                  <Link
                    moveTo="curriculo-contexto-vaga"
                    params={{
                      codigo_da_vaga: analysisContent.processo_seletivo.id,
                      codigo_do_candidato:
                        analysisContent.participante.candidato_id,
                      codigo_da_video_entrevista: Number(
                        analysisContent.video_entrevista.id
                      ),
                      codigo_da_listagem: analysisContent.participante.id,
                    }}
                    className="vg-btn-outline"
                  >
                    {intl.getHTML(
                      "VIDEO_ENTREVISTA.EMPRESA.VIDEO.VER_CURRICULO"
                    )}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="vg-container">
          <div className="vg-row">
            <div className="vg-col-xs-12 vg-col-md-8">
              <VideoInternal
                analysisContent={analysisContent}
                analysisVideo={analysisVideo}
                changeQuestion={this.changeQuestion}
                currentQuestion={currentQuestion}
                urlVisitor={paramUrlVisitor}
              />
            </div>
            <div className="vg-col-xs-12 vg-col-md-4">
              <aside role="complementary">
                {!!ratings.length && (
                  <VideoInterviewRatingsList
                    average={average_rating}
                    items={ratings}
                  />
                )}
                {!!comments.length && (
                  <VideoInterviewCommentsList items={comments} />
                )}
                <div className="vg-comment-input-container">
                  <textarea
                    name="comments"
                    id="comments"
                    disabled={isPostingComment}
                    value={this.state.comment}
                    placeholder={intl.get(
                      "VIDEO_ENTREVISTA.EMPRESA.VIDEO.CAMPO_INSERIR_COMENTARIO_PLACEHOLDER"
                    )}
                    onChange={(e) => this.handleUpdateText(e)}
                  />
                  <Button
                    disabled={isPostingComment}
                    className="vg-btn-block vg-btn-primary"
                    onClick={this.handlePostComment}
                  >
                    {intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.BOTAO")}
                  </Button>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    ) : error && !loaded ? (
      <div>
        <div className="vg-title-loader">
          <Icon iconKey="titleLoader" />
        </div>
        <div className="vg-container">
          <ErrorHandler
            text={intl.get("GRAPHS.ERROR_MESSAGE")}
            error={error}
            retryHandler={this.requestContent}
          />
        </div>
      </div>
    ) : (
      <div className="vg-video-page">
        <div className="vg-title-loader">
          <Icon iconKey="titleLoader" />
        </div>
        <div className="vg-container">
          <div className="vg-row">
            <div className="vg-col-xs-12 vg-col-md-8">
              <div className="vg-video-content">
                <div className="animation-loader">
                  <Icon iconKey="videoPlaceholder" />
                </div>
                <div className="animation-loader">
                  <Icon iconKey="textPlaceholder" />
                </div>
                <div className="animation-loader">
                  <Icon iconKey="textPlaceholder" />
                </div>
              </div>
            </div>
            <div className="vg-col-xs-12 vg-col-md-4">
              <aside>
                <div className="animation-loader">
                  <Icon iconKey="userPlaceholder" />
                </div>
                <div className="animation-loader">
                  <Icon iconKey="userFormPlaceholder" />
                </div>
                <div className="animation-loader">
                  <Icon iconKey="userFormPlaceholder" />
                </div>
                <div className="animation-loader">
                  <Icon iconKey="userFormPlaceholder" />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VideoMain.defaultProps = {
  loaded: false,
};

VideoMain.propTypes = {
  analysisContent: propTypes.object.isRequired,
  analysisVideo: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  isPostingComment: propTypes.bool.isRequired,
  load: propTypes.func.isRequired,
  get: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  error: propTypes.number,
  visitor: propTypes.bool,
  tokenShare: propTypes.any,
  location: propTypes.object,
};

export default withRouter(
  connect(mapStateToProps, (dispatch) =>
    bindActionCreators({ load, post, get }, dispatch)
  )(VideoMain)
);
