import { ErrorHandler, Icon, Link, PercentGraph } from "../../index";
import React, { Component, Fragment } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { receive } from "../../../store/modules/Graphs";
import { vpv } from "../../../utils/VirtualPageView";

const mapStateToProps = (state) => ({
  bceGraph: state.Graphs.capacidade_do_bce,
  loading: state.Graphs.capacidade_do_bce.loading,
  loaded: state.Graphs.capacidade_do_bce.loaded,
  error: state.Graphs.capacidade_do_bce.error,
  isCredits:
    (state.Painel.painel.data &&
      state.Painel.painel.data.cliente.modelo_creditos_vagas) ||
    false,
  flags:
    (state.Painel.painel.data && state.Painel.painel.data.feature_flags) || {},
});

const BceCardContent = ({ data }) => {
  return (
    <Fragment>
      <PercentGraph
        className="vg-graph vg-graph-bce"
        percentValue={data.serie[0].y}
      />
      <div className="vg-graph-label">
        <p>
          {intl.get("GRAPHS.BCE.LABEL", { value: data.total_utilizado })}{" "}
          <Link
            onClick={() => vpv("/funil-compras/creditos-banco-curriculo")}
            moveTo="triagem-bce"
          >
            {intl.get("GRAPHS.BCE.LINK")}
          </Link>
        </p>
      </div>
    </Fragment>
  );
};

BceCardContent.propTypes = {
  data: propTypes.object.isRequired,
};

const BceOverCardContent = ({ value }) => (
  <div className="vg-bce-disable">
    <p>{intl.getHTML("GRAPHS.BCE.TEXT", { value: value })} </p>
    <Link
      onClick={() => vpv("/funil-compras/creditos-ativar-bce")}
      moveTo="triagem-bce"
      className="vg-btn-success"
    >
      {intl.get("GRAPHS.BCE.ACTIVATE_LINK")}
    </Link>
  </div>
);

BceOverCardContent.propTypes = {
  value: propTypes.number.isRequired,
};

class GraphBce extends Component {
  constructor() {
    super();

    this.fetchGraph = this.fetchGraph.bind(this);
  }

  componentDidMount() {
    this.fetchGraph();
  }

  fetchGraph() {
    const { receive } = this.props;
    receive("capacidade_do_bce");
  }

  render() {
    const { loading, loaded, bceGraph, error, isCredits, flags } = this.props;
    const unlimitedBce = "bce_ilimitado";

    if (loading && !loaded) {
      return (
        <div className="vg-graphs-placeholder">
          <Icon iconKey="graphRounded" />
        </div>
      );
    }

    if (!loading && !loaded) {
      return (
        <ErrorHandler
          text={intl.get("GRAPHS.ERROR_MESSAGE")}
          error={error}
          retryHandler={this.fetchGraph}
        />
      );
    }

    if (!loading && loaded) {
      return (
        <div className="vg-graphs-content vg-graphs-bce">
          {flags?.includes(unlimitedBce) ? (
            <span className="bce-unlimited">
              <h3 className="vg-graphs-title">
                {intl.get("GRAPHS.BCE_UNLIMITED.TITLE")}
              </h3>
              <p>
                {intl.getHTML("GRAPHS.BCE_UNLIMITED.LABEL", {
                  value: bceGraph.data.total_utilizado,
                })}{" "}
              </p>
              <div className="vg-graph-label">
                <p>
                  <div className="bce-unlimited-box">
                    <Icon
                      iconKey="target"
                      addClass="icon-target"
                      aria-hidden="true"
                    />
                    {intl.getHTML("GRAPHS.BCE_UNLIMITED.TEXT")}
                  </div>
                  <Link
                    onClick={() =>
                      vpv("/funil-compras/creditos-banco-curriculo")
                    }
                    moveTo="triagem-bce"
                    aria-label={intl.get("GRAPHS.BCE_UNLIMITED.ACESSIBLE_LINK")}
                  >
                    {intl.get("GRAPHS.BCE_UNLIMITED.LINK")}
                  </Link>
                </p>
              </div>
            </span>
          ) : (
            <Fragment>
              <h3 className="vg-graphs-title">
                {intl.get("GRAPHS.BCE.TITLE")}
              </h3>
              {isCredits &&
              bceGraph.data.total_utilizado >= bceGraph.data.capacidade ? (
                <BceOverCardContent value={bceGraph.data.total_utilizado} />
              ) : (
                <BceCardContent data={bceGraph.data} />
              )}
            </Fragment>
          )}
        </div>
      );
    }
  }
}

GraphBce.DefaultProps = {
  isCredits: false,
  flags: [],
};

GraphBce.propTypes = {
  bceGraph: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  receive: propTypes.func.isRequired,
  error: propTypes.number,
  isCredits: propTypes.bool.isRequired,
  flags: propTypes.array.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(GraphBce);
