// Action Types
const VIDEO_LIST = "services/VIDEO_LIST";
const VIDEO_LIST_SUCCESS = "services/VIDEO_LIST_SUCCESS";
const VIDEO_LIST_FAIL = "services/VIDEO_LIST_FAIL";
const VIDEO_UPDATE = "services/VIDEO_UPDATE";
const VIDEO_UPDATE_SUCCESS = "services/VIDEO_UPDATE_SUCCESS";
const VIDEO_UPDATE_FAIL = "services/VIDEO_UPDATE_FAIL";

const VIDEO_LIST_FILTER = "services/VIDEO_LIST_FILTER";
const VIDEO_LIST_FILTER_SUCCESS = "services/VIDEO_LIST_FILTER_SUCCESS";
const VIDEO_LIST_FILTER_FAIL = "services/VIDEO_LIST_FILTER_FAIL";
// InitialState
const initialState = {
  loading: false,
  loaded: false,
  loadingMore: false,
  loadingList: false,
  data: {},
};

// Reducers
export default function videoInterviewListProcessed(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case VIDEO_LIST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case VIDEO_LIST_SUCCESS:
      return {
        ...state,
        data: action.result,
        loading: false,
        loaded: true,
      };
    case VIDEO_LIST_FAIL:
      return {
        loading: false,
        loaded: false,
        error: action.error.status,
      };
    case VIDEO_UPDATE:
      return {
        ...state,
        loadingMore: true,
      };
    case VIDEO_UPDATE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          pagina_atual: action.result.pagina_atual,
          participantes: [
            ...state.data.participantes,
            ...action.result.participantes,
          ],
        },
        loadingMore: false,
      };
    }

    case VIDEO_UPDATE_FAIL:
      return {
        loadingMore: false,
        error: action.error.status,
      };
    case VIDEO_LIST_FILTER:
      return {
        ...state,
        loadingList: true,
      };
    case VIDEO_LIST_FILTER_SUCCESS:
      return {
        ...state,
        loadingList: false,
        data: action.result,
      };
    case VIDEO_LIST_FILTER_FAIL:
      return {
        error: action.error.status,
        loadingList: false,
      };
    default:
      return state;
  }
}

// Actions
export function load(path, token = "access_token") {
  return {
    types: [VIDEO_LIST, VIDEO_LIST_SUCCESS, VIDEO_LIST_FAIL],
    promise: (client) =>
      client.get(path, {}, process.env.REACT_APP_ATHENA_URL, token),
  };
}
export function loadMore(path) {
  return {
    types: [VIDEO_UPDATE, VIDEO_UPDATE_SUCCESS, VIDEO_UPDATE_FAIL],
    promise: (client) => client.get(path),
  };
}

export function loadWithFilter(path) {
  return {
    types: [
      VIDEO_LIST_FILTER,
      VIDEO_LIST_FILTER_SUCCESS,
      VIDEO_LIST_FILTER_FAIL,
    ],
    promise: (client) => client.get(path),
  };
}

function shouldFetch() {
  return true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(path, token = "access_token") {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path, token));
    }
  };
}

export function receiveMore(path) {
  return (dispatch) => dispatch(loadMore(path));
}

export function receiveWithFilter(path) {
  return (dispatch) => dispatch(loadWithFilter(path));
}
