import React, { Component } from "react";
import {
  InterviewEmpty,
  InterviewListItem,
  LoadMoreItem,
  Icon,
  BoxShare,
} from "../../index";
import { map } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { receiveWithFilter } from "../../../store/modules/VideoInterviewListProcessed";
import { receiveMore } from "../../../store/modules/VideoInterviewListProcessed";
import { receiveNotMore } from "../../../store/modules/VideoInterviewListNotProcessed";
import { receiveOrdination } from "../../../store/modules/VideoInterviewListOrderFilterProcessed";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { Form, FormSpy } from "react-final-form";
import "./Interview.scss";

const mapStateToProps = (state) => ({
  order: state.VideoInterviewListOrderFilterProcessed.order,
  ordination: state.VideoInterviewListOrderFilterProcessed.ordination,
  orderField: state.VideoInterviewListOrderFilterProcessed.field,
  interviewList: state.VideoInterviewListProcessed.data,
  interviewListNot: state.VideoInterviewListNotProcessed.data,
  loadingMore: state.VideoInterviewListProcessed.loadingMore,
  loadingNotMore: state.VideoInterviewListNotProcessed.loadingMore,
  filterRating: state.VideoInterviewListOrderFilterProcessed.filters.rating,
  filterProcess: state.VideoInterviewListOrderFilterProcessed.filters.process,
  filterName: state.VideoInterviewListOrderFilterProcessed.filters.name,
  loadingList: state.VideoInterviewListProcessed.loadingList,
});

class Interview extends Component {
  constructor(props) {
    super(props);

    this.orderContent = this.orderContent.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.loadNotMore = this.loadNotMore.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.hasChangedVacancy = this.hasChangedVacancy.bind(this);

    this.state = {
      initial: {
        checks: [],
      },
    };
  }

  hasChangedVacancy(key) {
    const {
      interviewList: { participantes },
    } = this.props;

    return (
      key &&
      participantes[key].processo_seletivo_id !==
        participantes[key - 1].processo_seletivo_id
    );
  }

  handleCheckChange(checked) {
    const {
      interviewList: { participantes },
    } = this.props;
    const allIds = participantes.map((item) => item.id);

    this.setState(() => ({
      initial: {
        checks: checked ? allIds : [],
      },
    }));
  }

  allChecked(len) {
    return len && len === this.state.initial.checks.length;
  }

  orderContent(orderType = null) {
    const {
      interviewList,
      receiveWithFilter,
      receiveOrdination,
      order,
      filterRating,
      filterName,
      video_entrevista,
    } = this.props;

    let options = [
      "processo_seletivo_id=" + interviewList.processo_seletivo.id,
      "limite=10",
      "pagina=1",
      order ? "ordem=desc" : "ordem=asc",
      orderType ? "ordem_campo=" + orderType : "",
      filterRating[0]
        ? `pontuacao[]=${filterRating.join("&pontuacao[]=")}`
        : "",
      "status[]=processado",
      filterName ? `candidato_nome=${filterName}` : "",
    ];

    if (video_entrevista && video_entrevista != 0) {
      options.push("video_entrevista_id=" + video_entrevista);
    }

    receiveWithFilter(
      `video-entrevistas/participantes?${options.join("&")}`,
      orderType
    );
    receiveOrdination(options, orderType);
  }

  loadMore(id, page) {
    const {
      ordination,
      receiveMore,
      filterRating,
      filterName,
      video_entrevista,
    } = this.props;
    const newPage = page + 1;

    let options = [
      "video_entrevista_id=" + video_entrevista,
      "processo_seletivo_id=" + id,
      "limite=10",
      "pagina=" + newPage,
      "ordem=desc",
      filterRating[0]
        ? `pontuacao[]=${filterRating.join("&pontuacao[]=")}`
        : "",
      "status[]=processado",
      filterName ? `candidato_nome=${filterName}` : "",
    ];

    let url = "";

    if (ordination.length > 0) {
      let pageIndex = ordination.indexOf(`pagina=${page}`);
      ordination[pageIndex] = `pagina=${newPage}`;
      url = `video-entrevistas/participantes?${ordination.join("&")}`;
    } else {
      url = `video-entrevistas/participantes?${options.join("&")}`;
    }
    receiveMore(url);
  }

  loadNotMore(id, page) {
    const { receiveNotMore, filterName, filterProcess, video_entrevista } =
      this.props;
    const newPage = page + 1;
    let options = [
      "video_entrevista_id=" + video_entrevista,
      "processo_seletivo_id=" + id,
      "limite=10",
      "pagina=" + newPage,
      "ordem=desc",
      filterName ? `candidato_nome=${filterName}` : "",
    ];
    let stringProcess = "";
    if (filterProcess.includes("convite_pendente")) {
      stringProcess = "status[]=convite_pendente";
    } else if (filterProcess.includes("prazo_expirado")) {
      stringProcess = stringProcess ? `${stringProcess}&` : "";
      stringProcess += "status[]=prazo_expirado";
    } else {
      stringProcess += "status[]=nao_processado";
    }
    receiveNotMore(
      `video-entrevistas/participantes?${options.join("&")}${stringProcess}`
    );
  }

  render() {
    const { initial } = this.state;
    const {
      loadingList,
      loadingMore,
      loadingNotMore,
      interviewList,
      interviewListNot,
      orderField,
      order,
      visitor,
      tokenShare,
      urlVisitor,
    } = this.props;
    if (loadingList) {
      return (
        <div className='vg-container'>
          <div className='vg-title-loader'>
            <Icon iconKey='titleLoader' />
          </div>
          <div className='vg-list-placeholder'>
            <Icon iconKey='loaderList' />
          </div>
          <div className='vg-list-placeholder'>
            <Icon iconKey='loaderList' />
          </div>
          <div className='vg-list-placeholder'>
            <Icon iconKey='loaderList' />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Form
            onSubmit={() => {}}
            initialValues={initial}
            subscription={{ submitting: true, pristine: true }}
          >
            {() => (
              <React.Fragment>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => (
                    <React.Fragment>
                      <BoxShare participantes={values.checks} />
                    </React.Fragment>
                  )}
                </FormSpy>
                {interviewList.participantes.length ? (
                  <div className='vg-video-interview-load-more'>
                    <div className='vg-item-participant vg-participant-header clearfix'>
                      {!urlVisitor && (
                        <div className='vg-custom-check vg-check-block'>
                          <div className='vg-check-all'>
                            <span className='vg-input-checkbox'>
                              <input
                                id='check-all'
                                type='checkbox'
                                onChange={(el) =>
                                  this.handleCheckChange(el.target.checked)
                                }
                              />
                              <span className='vg-input-checkbox-inner' />
                            </span>
                            <label
                              htmlFor='check-all'
                              className='vg-check-all-label'
                            >
                              {intl.get(
                                "VIDEO_ENTREVISTA.EMPRESA.LISTA.MARCAR_TODOS",
                                {
                                  value: this.allChecked(
                                    interviewList.participantes.length
                                  ),
                                }
                              )}
                            </label>
                          </div>
                        </div>
                      )}
                      {!tokenShare && (
                        <div
                          className={`vg-item-data ${
                            tokenShare ? "" : "vg-extended-left-margin"
                          }`}
                        >
                          <div className='vg-item-infos'>
                            <p
                              className={
                                (orderField === "nome_candidato"
                                  ? "order-" + order
                                  : "") + (!tokenShare ? "" : "token-share")
                              }
                              onClick={
                                !tokenShare
                                  ? () => this.orderContent("nome_candidato")
                                  : undefined
                              }
                            >
                              {intl.get(
                                "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.NOME"
                              )}
                            </p>
                          </div>
                          <div className='vg-types'>
                            <div className='vg-rating'>
                              <p
                                className={
                                  (orderField === "pontuacao"
                                    ? "order-" + order
                                    : "") + (!tokenShare ? "" : "token-share")
                                }
                                onClick={
                                  !tokenShare
                                    ? () => this.orderContent("pontuacao")
                                    : undefined
                                }
                              >
                                {intl.get(
                                  "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.AVALIACAO"
                                )}
                              </p>
                            </div>
                            <div className='vg-item-analysis'>
                              <p
                                className={
                                  (orderField === "comunicacao"
                                    ? "order-" + order
                                    : "") + (!tokenShare ? "" : "token-share")
                                }
                                onClick={
                                  !tokenShare
                                    ? () => this.orderContent("comunicacao")
                                    : undefined
                                }
                              >
                                {intl.get(
                                  "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.COMUNICACAO"
                                )}
                              </p>
                            </div>
                            <div className='vg-item-analysis'>
                              <p
                                className={
                                  (orderField === "postura"
                                    ? "order-" + order
                                    : "") + (!tokenShare ? "" : "token-share")
                                }
                                onClick={
                                  !tokenShare
                                    ? () => this.orderContent("postura")
                                    : undefined
                                }
                              >
                                {intl.get(
                                  "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.POSTURA"
                                )}
                              </p>
                            </div>
                            <div className='vg-item-analysis'>
                              <p
                                className={
                                  (orderField === "aderencia"
                                    ? "order-" + order
                                    : "") + (!tokenShare ? "" : "token-share")
                                }
                                onClick={
                                  !tokenShare
                                    ? () => this.orderContent("aderencia")
                                    : undefined
                                }
                              >
                                {intl.get(
                                  "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.ADERENCIA"
                                )}
                              </p>
                            </div>
                            <div className='vg-item-analysis'>
                              <p
                                className={
                                  (orderField === "cuidado_video"
                                    ? "order-" + order
                                    : "") + (!tokenShare ? "" : "token-share")
                                }
                                onClick={
                                  !tokenShare
                                    ? () => this.orderContent("cuidado_video")
                                    : undefined
                                }
                              >
                                {intl.get(
                                  "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.CUIDADO_VIDEO"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {map(interviewList.participantes, (item, key) => {
                      return (
                        <React.Fragment>
                          {(this.hasChangedVacancy(key) || key === 0) &&
                            tokenShare && (
                              <React.Fragment>
                                <h2 className='vg-name-vacancy vg-videointerview-name-vacancy'>
                                  {`${
                                    interviewList.participantes[key].cargo ||
                                    "Vaga:"
                                  }
                                (v${
                                  interviewList.participantes[key]
                                    .processo_seletivo_id
                                })`}
                                </h2>
                                <div className='vg-item-participant vg-participant-header clearfix'>
                                  {!urlVisitor && (
                                    <div className='vg-custom-check vg-check-block'>
                                      <div className='vg-check-all'>
                                        <span className='vg-input-checkbox'>
                                          <input
                                            id='check-all'
                                            type='checkbox'
                                            onChange={(el) =>
                                              this.handleCheckChange(
                                                el.target.checked
                                              )
                                            }
                                          />
                                          <span className='vg-input-checkbox-inner' />
                                        </span>
                                        <label
                                          htmlFor='check-all'
                                          className='vg-check-all-label'
                                        >
                                          {intl.get(
                                            "VIDEO_ENTREVISTA.EMPRESA.LISTA.MARCAR_TODOS",
                                            {
                                              value: this.allChecked(
                                                interviewList.participantes
                                                  .length
                                              ),
                                            }
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    className={`vg-item-data ${
                                      tokenShare
                                        ? ""
                                        : "vg-extended-left-margin"
                                    }`}
                                  >
                                    <div className='vg-item-infos'>
                                      <p
                                        className={
                                          (orderField === "nome_candidato"
                                            ? "order-" + order
                                            : "") +
                                          (!tokenShare ? "" : "token-share")
                                        }
                                        onClick={
                                          !tokenShare
                                            ? () =>
                                                this.orderContent(
                                                  "nome_candidato"
                                                )
                                            : undefined
                                        }
                                      >
                                        {intl.get(
                                          "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.NOME"
                                        )}
                                      </p>
                                    </div>
                                    <div className='vg-types'>
                                      <div className='vg-rating'>
                                        <p
                                          className={
                                            (orderField === "pontuacao"
                                              ? "order-" + order
                                              : "") +
                                            (!tokenShare ? "" : "token-share")
                                          }
                                          onClick={
                                            !tokenShare
                                              ? () =>
                                                  this.orderContent("pontuacao")
                                              : undefined
                                          }
                                        >
                                          {intl.get(
                                            "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.AVALIACAO"
                                          )}
                                        </p>
                                      </div>
                                      <div className='vg-item-analysis'>
                                        <p
                                          className={
                                            (orderField === "comunicacao"
                                              ? "order-" + order
                                              : "") +
                                            (!tokenShare ? "" : "token-share")
                                          }
                                          onClick={
                                            !tokenShare
                                              ? () =>
                                                  this.orderContent(
                                                    "comunicacao"
                                                  )
                                              : undefined
                                          }
                                        >
                                          {intl.get(
                                            "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.COMUNICACAO"
                                          )}
                                        </p>
                                      </div>
                                      <div className='vg-item-analysis'>
                                        <p
                                          className={
                                            (orderField === "postura"
                                              ? "order-" + order
                                              : "") +
                                            (!tokenShare ? "" : "token-share")
                                          }
                                          onClick={
                                            !tokenShare
                                              ? () =>
                                                  this.orderContent("postura")
                                              : undefined
                                          }
                                        >
                                          {intl.get(
                                            "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.POSTURA"
                                          )}
                                        </p>
                                      </div>
                                      <div className='vg-item-analysis'>
                                        <p
                                          className={
                                            (orderField === "aderencia"
                                              ? "order-" + order
                                              : "") +
                                            (!tokenShare ? "" : "token-share")
                                          }
                                          onClick={
                                            !tokenShare
                                              ? () =>
                                                  this.orderContent("aderencia")
                                              : undefined
                                          }
                                        >
                                          {intl.get(
                                            "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.ADERENCIA"
                                          )}
                                        </p>
                                      </div>
                                      <div className='vg-item-analysis'>
                                        <p
                                          className={
                                            (orderField === "cuidado_video"
                                              ? "order-" + order
                                              : "") +
                                            (!tokenShare ? "" : "token-share")
                                          }
                                          onClick={
                                            !tokenShare
                                              ? () =>
                                                  this.orderContent(
                                                    "cuidado_video"
                                                  )
                                              : undefined
                                          }
                                        >
                                          {intl.get(
                                            "VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.CUIDADO_VIDEO"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          <div key={item.id} className='vg-item-participant'>
                            <InterviewListItem
                              item={item}
                              vaga_id={interviewList.processo_seletivo.id}
                              visitor={visitor}
                              urlVisitor={urlVisitor}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })}
                    {interviewList.total_paginas !==
                      interviewList.pagina_atual &&
                      !tokenShare && (
                        <LoadMoreItem
                          carregarMais={() =>
                            this.loadMore(
                              interviewList.processo_seletivo.id,
                              interviewList.pagina_atual
                            )
                          }
                          loading={loadingMore}
                        />
                      )}
                  </div>
                ) : (
                  <InterviewEmpty visitor={visitor} />
                )}
                {!tokenShare && interviewListNot.participantes ? (
                  <React.Fragment>
                    <div className='vg-box-title vg-box-title-second vg-box-title-interviewListNot'>
                      <h3>
                        {intl.get("VIDEO_ENTREVISTA.EMPRESA.LISTA.PENDENTES")}
                      </h3>
                      <p>
                        {intl.get(
                          "VIDEO_ENTREVISTA.EMPRESA.LISTA.SUB_TITULO_2"
                        )}
                      </p>
                    </div>
                    <div>
                      {map(interviewListNot.participantes, (item) => {
                        return (
                          <div
                            key={item.id}
                            className='vg-item-participant vg-not-responded clearfix'
                          >
                            <InterviewListItem item={item} isPending={true} />
                          </div>
                        );
                      })}
                      {interviewListNot.total_paginas !==
                        interviewListNot.pagina_atual && (
                        <LoadMoreItem
                          carregarMais={() =>
                            this.loadNotMore(
                              interviewListNot.processo_seletivo.id,
                              interviewListNot.pagina_atual
                            )
                          }
                          loading={loadingNotMore}
                        />
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  !tokenShare && <InterviewEmpty />
                )}
              </React.Fragment>
            )}
          </Form>
        </div>
      );
    }
  }
}

Interview.propTypes = {
  interviewList: propTypes.object.isRequired,
  interviewListNot: propTypes.object.isRequired,
  loadingMore: propTypes.bool.isRequired,
  loadingNotMore: propTypes.bool.isRequired,
  loadingList: propTypes.bool,
  receiveWithFilter: propTypes.func.isRequired,
  receiveMore: propTypes.func,
  receiveNotMore: propTypes.func,
  receiveOrdination: propTypes.func,
  order: propTypes.bool.isRequired,
  ordination: propTypes.array,
  orderField: propTypes.string,
  filterRating: propTypes.array,
  filterProcess: propTypes.array,
  filterName: propTypes.string,
  video_entrevista: propTypes.string,
  visitor: propTypes.bool,
  tokenShare: propTypes.any,
  urlVisitor: propTypes.bool,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    { receiveWithFilter, receiveMore, receiveNotMore, receiveOrdination },
    dispatch
  )
)(Interview);
