import React, { Component } from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import { connect } from "react-redux";
import { map } from "lodash";
import { TabPanelItem } from "../../index";
import "./TabPanel.scss";

class TabPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { accesses } = this.props;

    const tabPanelFiltered =
      this.props.currentTab === "gestao_da_vaga" &&
      accesses.feature_flags &&
      accesses.feature_flags.includes("construtor-testes")
        ? [
            "construtor-testes",
            ...this.props.tabPanel.filter((i) => i !== "construtor-testes"),
          ]
        : this.props.tabPanel;

    return (
      <div className="vg-content-tabs">
        <div className="vg-content-tab">
          {/* O link abaixo deverá ser removido quando a rota de configurações passar a retornar a
          respectiva chave. */}
          {this.props.currentTab === "gestao_da_vaga" && (
            <>
              <div className="vg-content-item clearfix">
                <p className="vg-title-content">
                  {intl.get("CONFIGURACOES.CONTEUDO_ABAS.FORMULARIOS.title")}
                </p>
                <div className="badge-container">
                  <div className="badge">
                    <box-icon
                      type="solid"
                      name="bell"
                      color="#12B76A"
                      data-icon="box-icon"
                      aria-hidden="true"
                    ></box-icon>
                    <span>
                      {intl.get(
                        "CONFIGURACOES.CONTEUDO_ABAS.FORMULARIOS.badge"
                      )}
                    </span>
                  </div>
                  <a
                    className="vg-btn-link"
                    href="/formularios"
                    id="formularios"
                  >
                    {intl.get("CONFIGURACOES.CONTEUDO_ABAS.FORMULARIOS.button")}
                  </a>
                </div>
              </div>
            </>
          )}
          {map(tabPanelFiltered, (item) =>
            item !== "twitter" ? (
              <TabPanelItem currentLine={item} key={item} />
            ) : null
          )}
        </div>
      </div>
    );
  }
}

TabPanel.defaultProps = {
  tabPanel: [],
  currentTab: "",
  accesses: {
    feature_flags: [],
  },
};

TabPanel.propTypes = {
  tabPanel: propTypes.array.isRequired,
  currentTab: propTypes.string.isRequired,
  accesses: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accesses: state.Painel.painel.data,
});

export default connect(mapStateToProps, null)(TabPanel);
