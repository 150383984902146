import React, { useEffect, useState } from "react";
import "./SelectiveProcessList.scss";
import {
  ErrorHandler,
  Icon,
  LoadMore,
  SelectiveProcessListItem,
  SelectiveProcessState,
  Link,
} from "../../index";
import {
  changeHiddenStatusRequest,
  load,
  requestUpdateVacancy,
} from "../../../store/modules/Vacancies";
import { receive } from "../../../store/modules/Painel";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { map } from "lodash";
import propTypes from "prop-types";
import flattenProps from "../../../utils/flatten-props";
import { intlReplace } from "../../../utils/IntlReplace";
import { receive as receiveVacanciesStatus } from "../../../store/modules/VacanciesStatus";
import { toast } from "react-toastify";
import { Toast } from "../../../components/index";

const emptyLocation = { cidade: "", estado: "", pais: "", descricao: "" };

const mapStateToProps = (state) => ({
  vacancies: state.Vacancies,
  vacanciesStatus: state.VacanciesStatus.data,
  sortingDefault: state.Painel.painel.data
    ? state.Painel.painel.data.funcionario.configuracoes.triagem_padrao
    : "",
  isOpenTriagem: state.SelectSorting.modal,
  idTriagem: state.SelectSorting.id ? state.SelectSorting.id : null,
  featureFlags: state.Painel.painel.data
    ? state.Painel.painel.data.feature_flags
    : [],
  isVagas25: state.Painel.painel.data
    ? state.Painel.painel.data.cliente.cadastro_vagas25
    : false,
});

const SelectiveProcessList = ({
  type,
  path,
  vacancies,
  title,
  subtitle,
  sortingDefault,
  featureFlags,
  vacanciesStatus,
  hideRequest,
  load,
  panel,
  isVagas25,
  ...props
}) => {
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [lastVacancies, setLastVacancies] = useState({});
  const [tempListVacancies, setTempListVacancies] = useState({
    all: [],
    home: [],
    hidden: [],
    yours: [],
  });
  const [count, setCount] = useState(1);
  const [paginate, setPaginate] = useState(false);
  const [totalPagina, setTotalPagina] = useState(
    vacancies[type]["totalPagina"]
  );

  let newOrdinationOn = false;
  let newVacancyStatusOn = false;

  if (featureFlags) {
    newOrdinationOn = featureFlags.includes("nova_ordenacao_home");
    newVacancyStatusOn = featureFlags.includes("novo_status_vaga");
  }

  useEffect(() => {
    loadContentHandler();
    const { receive, receiveVacanciesStatus } = props;

    // Carrega as permissões do painel
    receive("painel", "painel");
    receiveVacanciesStatus("status_vagas");
  }, []);

  function loadContentHandler() {
    load(type, path);
  }

  useEffect(() => {
    setTotalPagina(vacancies[type]["totalPagina"]);

    if (vacancies[type].loaded && !firstLoaded) {
      setFirstLoaded(true);
      setTempListVacancies({ [type]: vacancies[type].vacancies });
    }

    if (
      firstLoaded &&
      paginate &&
      vacancies[type].vacancies !== lastVacancies
    ) {
      setTempListVacancies({
        [type]: [...tempListVacancies[type], ...vacancies[type].vacancies],
      });
      setPaginate(false);
    }

    setLastVacancies(vacancies[type]["vacancies"]);
  }, [vacancies[type]]);

  function carregarMais(type) {
    setPaginate(true);
    let countAux = count + 1;
    setCount(countAux);
    load(type, path, { params: { pagina: countAux } });
  }

  function hideVacancy(el, id) {
    el.preventDefault();

    hideRequest(id).then(() => {
      const toDelete = new Set([id]);
      const newArray = tempListVacancies[type].filter(
        (obj) => !toDelete.has(obj.id)
      );

      setTempListVacancies({ [type]: newArray });
    });

    toast(
      <Toast
        message={intl.getHTML("VAGAS.OCULTAR_VAGA", { vaga: id })}
        type="success"
      />,
      {
        position: "bottom-right",
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        className: "sr-toast vg-toast-success",
      }
    );
  }

  function updateVacanciesTempList(vacancyId, updatedValue) {
    setTempListVacancies({
      [type]: tempListVacancies[type].map((vaga) => {
        if (vaga.id === vacancyId) {
          return { ...vaga, ...updatedValue };
        }
        return vaga;
      }),
    });
  }

  return vacancies[type].loading && !paginate ? (
    <div>
      {type === "home" ? null : (
        <div className="vg-title-loader">
          <Icon iconKey="titleLoader" />
        </div>
      )}
      <div className="vg-vacancy-loader">
        <Icon iconKey="jobLoader" />
      </div>
      <div className="vg-vacancy-loader">
        <Icon iconKey="jobLoader" />
      </div>
    </div>
  ) : vacancies[type].error ? (
    <ErrorHandler
      text={intl.getHTML("MENSAGEM_DE_ERRO.VAGA_NAO_CARREGADA")}
      retryHandler={loadContentHandler}
    />
  ) : (
    <div>
      {type === "home" && (
        <h2 className="vg-title-1 vg-title-left">
          {newOrdinationOn
            ? intl.get("SERVICOS.VAGAS_INTERACAO_RECENTE")
            : intl.get("SERVICOS.MINHAS_VAGAS")}
        </h2>
      )}
      {Object.keys(tempListVacancies[type]).length === 0 &&
      type === "all" ? null : (
        <div>
          {title && (
            <h2 className="vg-title-1 vg-title-vacancies">
              {title}
              {subtitle && <span className="vg-sub-tit">{subtitle}</span>}
            </h2>
          )}
        </div>
      )}
      <ul className="vg-list-wrap">
        {Object.keys(tempListVacancies[type]).length !== 0 ? (
          map(tempListVacancies[type], (obj) => (
            <SelectiveProcessListItem
              key={obj.id}
              id={obj.id}
              expirationDate={obj.data_de_expiracao}
              openDate={obj.data_de_abertura}
              creationDate={obj.data_de_criacao}
              limiteDate={obj.data_limite_veiculacao}
              vacancy={obj.cargo}
              pcd={obj.exclusiva_pcd}
              phases={obj.fases}
              totalAfterSorting={obj.total_candidaturas_apos_ultima_triagem}
              status={obj.status && intlReplace(obj.status)}
              currentStatus={
                obj.status_atual && intlReplace(obj.status_atual.descricao)
              }
              availableStatus={vacanciesStatus}
              buttons={obj.acoes.botoes}
              acoes={obj.acoes}
              menu={obj.acoes.menu}
              total={obj.total_candidatos}
              employee={obj.responsavel.id}
              positions={obj.posicoes}
              isIntern={obj.fechamento_com_candidatos_internos}
              type={type}
              path={path}
              sla={obj.sla}
              responsavel={obj.responsavel}
              grupo={obj.grupo}
              divisao={obj.divisao_veiculacao}
              test={obj.teste}
              vacancyType={obj.tipo_processo}
              hideVacancy={hideVacancy}
              sortingDefault={sortingDefault}
              openingDateSituation={obj.situacao_data_veiculacao}
              location={
                obj.localizacao
                  ? flattenProps(obj.localizacao, "nome")
                  : emptyLocation
              }
              requestUpdateVacancy={requestUpdateVacancy}
              visualizations={obj.total_visualizacoes}
              newVacancyStatusOn={newVacancyStatusOn}
              updateVacanciesTempList={updateVacanciesTempList}
              featureFlags={featureFlags}
              panel={panel}
              vagaInteligente={obj.vaga_inteligente_criada}
              hideVeiculacao={obj.oculta_alterar_veiculacao}
            />
          ))
        ) : type === "all" ? null : (
          <SelectiveProcessState>
            <p>
              {!isVagas25 ? (
                <Icon iconKey="file" aria-hidden="true" />
              ) : (
                <span className="vg-icon-bg-border bg-border-gray vg-icon-sm">
                  <Icon iconKey="briefcase2" aria-hidden="true" />
                </span>
              )}
              <span className="vg-icon-border"></span>
              {!isVagas25 ? (
                intl.get("SERVICOS.NAO_HA_VAGAS_PUBLICADAS.TITULO")
              ) : (
                <>
                  <b className="vg-v25-b">
                    {intl.get("SERVICOS.NAO_HA_VAGAS_PUBLICADAS.TITULO2")}
                  </b>
                  <span className="vg-v25-span">
                    {intl.get("SERVICOS.NAO_HA_VAGAS_PUBLICADAS.SUBTITULO2")}
                  </span>
                  <Link
                    href="/move-to/divulgar-vagas"
                    className="vg-btn vg-btn-outline-light-gray vg-btn-float-right"
                  >
                    {intl.get("HOME.CONTEUDO.BOTAO_CRIAR_VAGA")}
                  </Link>
                </>
              )}
            </p>
          </SelectiveProcessState>
        )}
      </ul>
      {totalPagina > count && (
        <LoadMore
          carregarMais={() => carregarMais(type)}
          loading={vacancies[type].loading}
        />
      )}
    </div>
  );
};

SelectiveProcessList.defaultProps = {
  vacancies: {
    home: { vacancies: [] },
    all: { vacancies: [] },
    yours: { vacancies: [] },
  },
  paginate: false,
  vacanciesStatus: [],
};

SelectiveProcessList.propTypes = {
  vacancies: propTypes.object.isRequired,
  totalPagina: propTypes.any,
  load: propTypes.func.isRequired,
  hideRequest: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  path: propTypes.string.isRequired,
  title: propTypes.any,
  subtitle: propTypes.any,
  receive: propTypes.func.isRequired,
  sortingDefault: propTypes.string.isRequired,
  idTriagem: propTypes.number,
  featureFlags: propTypes.array.isRequired,
  receiveVacanciesStatus: propTypes.func.isRequired,
  vacanciesStatus: propTypes.array.isRequired,
  panel: propTypes.object,
  isVagas25: propTypes.bool,
};

export default connect(mapStateToProps, {
  load,
  hideRequest: changeHiddenStatusRequest,
  receive,
  receiveVacanciesStatus,
})(SelectiveProcessList);
