import { withRouter } from "react-router";
import propTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { load, clean } from "../../../store/modules/GroupsVideos";

const mapStateToProps = (state) => ({
  GroupsVideos: state.GroupsVideos.data,
  loaded: state.GroupsVideos.loaded,
});

class GroupsVideos extends Component {
  constructor(props) {
    super(props);
    this.handleChangeGroups = this.handleChangeGroups.bind(this);
    const { selectGroups } = props;
    this.state = {
      selectGroups: selectGroups || "0",
    };
  }

  handleChangeGroups(value) {
    this.setState({
      selectGroups: value,
    });
    this.props.comboValue(value);
  }

  componentDidMount() {
    const { load, processo_seletivo_id } = this.props;
    load(processo_seletivo_id);
  }

  componentWillUnmount() {
    const { clean } = this.props;
    clean();
  }

  componentDidUpdate(prevProps) {
    const { GroupsVideos, selectGroups } = this.props;

    if (
      (!prevProps.GroupsVideos?.grupos && GroupsVideos?.grupos) ||
      prevProps.selectGroups !== selectGroups
    ) {
      this.setState({
        selectGroups: selectGroups,
      });
    }
  }

  render() {
    const { GroupsVideos, loaded } = this.props;
    const { selectGroups } = this.state;

    const options = loaded
      ? GroupsVideos.grupos.map((item) => (
          <option key={item.id} value={item.id}>
            {item.identificacao}
          </option>
        ))
      : "";
    const select = (
      <div className="vg-inline-block">
        <div className="vg-custom-select vg-custom-select-subtitle">
          <select
            value={selectGroups}
            onChange={(el) => this.handleChangeGroups(el.target.value)}
            className="vg-select-groups"
          >
            <option value={0} disabled>
              Escolha o grupo
            </option>
            {options}
          </select>
        </div>
      </div>
    );
    return loaded && select;
  }
}

GroupsVideos.defaultProps = {
  GroupsVideos: {},
  type: "",
  loading: false,
};

GroupsVideos.propTypes = {
  GroupsVideos: propTypes.object.isRequired,
  load: propTypes.func.isRequired,
  clean: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  processo_seletivo_id: propTypes.number.isRequired,
  comboValue: propTypes.func.isRequired,
  selectGroups: propTypes.string.isRequired,
};

export default withRouter(
  connect(mapStateToProps, (dispatch) =>
    bindActionCreators({ load, clean }, dispatch)
  )(GroupsVideos)
);
