import React, { Component } from "react";
import {
  BoxTitle,
  ErrorHandler,
  Icon,
  Interview,
  SearchInterview,
  Link,
  GroupsVideos,
} from "../../index";
import "./index.scss";
import { last } from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { receive } from "../../../store/modules/VideoInterviewListProcessed";
import { receiveNot } from "../../../store/modules/VideoInterviewListNotProcessed";
import { receiveGroup } from "../../../store/modules/VideoGroupInterview";

const mapStateToProps = (state) => ({
  interviewGroup: state.VideoGroupInterview.data,
  interviewList: state.VideoInterviewListProcessed.data,
  interviewListNot: state.VideoInterviewListNotProcessed.data,
  loading: state.VideoInterviewListProcessed.loading,
  loaded: state.VideoInterviewListProcessed.loaded,
  error: state.VideoInterviewListProcessed.error,
  order: state.VideoInterviewListProcessed.order,
});

class VideoInterview extends Component {
  constructor(props) {
    super(props);
    // Bind dos métodos
    this.initialContent = this.initialContent.bind(this);
    this.comboValue = this.comboValue.bind(this);
    this.state = {
      selectGroups: props.interviewGroup.grupos
        ? props.interviewGroup.grupos[0].id
        : "0",
    };
  }

  componentDidMount() {
    const { match, receiveGroup, tokenShare } = this.props;
    if (!tokenShare) {
      const query = last(match.params.id.split("/"));
      receiveGroup(
        `video-entrevistas/grupos?processo_seletivo_id=${query}&remover_perguntas=true`
      ).then((response) => {
        if (response) {
          this.initialContent();
        }
      });
    } else {
      this.initialContent();
    }
  }

  componentDidUpdate(prevProps) {
    const { interviewGroup } = this.props;
    
    if ((!prevProps.interviewGroup.grupos && interviewGroup.grupos) ||
        (prevProps.interviewGroup.grupos && 
         prevProps.interviewGroup.grupos[0]?.id !== interviewGroup.grupos?.[0]?.id)) {
      this.setState({
        selectGroups: interviewGroup.grupos[0]?.id.toString() || "0"
      });
    }
  }

  initialContent() {
    const { match, receive, receiveNot, interviewGroup, tokenShare, visitor } = this.props;
    if (!tokenShare) {
      const query = last(match.params.id.split("/"));
      let video_entrevista = interviewGroup?.grupos?.[0]?.id || "0";
      let options = [
        "processo_seletivo_id=" + query,
        "limite=10",
        "ordem=desc",
      ];

      if (video_entrevista && video_entrevista !== "0") {
        options.push("video_entrevista_id=" + video_entrevista);
      }
      receive(
        `video-entrevistas/participantes?status[]=processado&${options.join("&")}`
      );
      receiveNot(
        "video-entrevistas/participantes?status[]=nao_processado&" + options.join("&")
      );

      this.setState({
        selectGroups: video_entrevista.toString(),
      });
    } else {
      const token = visitor ? "compartilhamento_token" : "access_token";
      receive(
        `video-entrevistas/participantes?limite=4000&pagina=1&ordem_campo=processo_seletivo_id&convidado_token=${tokenShare}`,
        token
      );
    }
  }

  comboValue(value) {
    const { match, receive, receiveNot } = this.props;
    const query = last(match.params.id.split("/"));
    let video_entrevista = value;

    let options = ["processo_seletivo_id=" + query, "limite=10", "ordem=desc"];

    if (video_entrevista && video_entrevista != 0) {
      options.push("video_entrevista_id=" + video_entrevista);
    }

    receive(
      "video-entrevistas/participantes?status[]=processado&" + options.join("&")
    );
    receiveNot(
      "video-entrevistas/participantes?status[]=nao_processado&" +
        options.join("&")
    );

    this.setState({
      selectGroups: value,
    });
  }

  render() {
    const { loaded, error, interviewList, visitor, tokenShare, urlVisitor } =
      this.props;
    const { selectGroups } = this.state;
    const boxTitleText = tokenShare
      ? "VIDEO_ENTREVISTA.EMPRESA.LISTA.TITULO_SHARE"
      : "VIDEO_ENTREVISTA.EMPRESA.LISTA.TITULO";
    const boxSubTitle = tokenShare
      ? "VIDEO_ENTREVISTA.EMPRESA.LISTA.SUB_TITULO_SHARE"
      : "VIDEO_ENTREVISTA.EMPRESA.LISTA.SUB_TITULO";
    return loaded ? (
      <div className="vg-page-list-participants">
        <div className="vg-container">
          <BoxTitle
            title={intl.get(boxTitleText, {
              nome: interviewList.processo_seletivo.cargo,
              vaga_id: interviewList.processo_seletivo.id,
            })}
            secondLevel
          >
            <div className="vg-video-interview-subtitle">
              {intl.get(boxSubTitle, {
                candidatos: interviewList.participantes
                  ? interviewList.participantes.length
                  : 0,
              })}
              {!urlVisitor && (
                <GroupsVideos
                  processo_seletivo_id={interviewList.processo_seletivo.id}
                  comboValue={this.comboValue}
                  selectGroups={selectGroups}
                />
              )}
              {!visitor && (
                <Link
                  className="vg-link"
                  moveTo="ver-vaga-publicada"
                  params={{
                    codigo_da_vaga: interviewList.processo_seletivo.id,
                  }}
                >
                  {!tokenShare &&
                    intl.get("VIDEO_ENTREVISTA.EMPRESA.LISTA.LINK_VAGA")}
                </Link>
              )}
            </div>
          </BoxTitle>
          {!tokenShare && <SearchInterview video_entrevista={selectGroups} />}
          <Interview
            video_entrevista={selectGroups}
            visitor={visitor}
            tokenShare={tokenShare}
            urlVisitor={urlVisitor}
          />
        </div>
      </div>
    ) : error && !loaded ? (
      <div className="vg-page-list-participants">
        <div className="vg-container">
          <div className="vg-title-loader">
            <Icon iconKey="titleLoader" />
          </div>
          <ErrorHandler
            text={intl.get("GRAPHS.ERROR_MESSAGE")}
            error={error}
            retryHandler={this.initialContent}
          />
        </div>
      </div>
    ) : (
      <div className="vg-container">
        <div className="vg-title-loader">
          <Icon iconKey="titleLoader" />
        </div>
        <div className="vg-list-placeholder">
          <Icon iconKey="loaderList" />
        </div>
        <div className="vg-list-placeholder">
          <Icon iconKey="loaderList" />
        </div>
        <div className="vg-list-placeholder">
          <Icon iconKey="loaderList" />
        </div>
      </div>
    );
  }
}

VideoInterview.propTypes = {
  interviewList: propTypes.object.isRequired,
  interviewGroup: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  receive: propTypes.func.isRequired,
  receiveNot: propTypes.func.isRequired,
  receiveGroup: propTypes.func.isRequired,
  error: propTypes.number,
  match: propTypes.object,
  visitor: propTypes.bool,
  tokenShare: propTypes.any,
  urlVisitor: propTypes.bool,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive, receiveNot, receiveGroup }, dispatch)
)(VideoInterview);
