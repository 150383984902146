import React, { Component } from "react";
import { setCookie } from "../cookie/cookie.util";

import { Button, H1, styled } from "@vagastec/epartner-ui";

const companies = [
  { companyId: 5800, userId: 8636, name: "Femme", clientId: "frontend" }, // EuroJob
  { companyId: 39401, userId: 173562, name: "EuroJob", clientId: "frontend" }, // EuroJob
  {
    companyId: 64406,
    userId: 216054,
    name: "Votorantim",
    clientId: "frontend",
  }, // Votorantim
  { companyId: 5511, userId: 8329, name: "Tim", clientId: "frontend" }, // Tim
  { companyId: 12702, userId: 53863, name: "Teste01", clientId: "frontend" }, // Teste01
  {
    companyId: 12702,
    userId: 17742,
    name: "Teste01 - Admin",
    clientId: "frontend",
  }, // Teste01
  {
    companyId: 12702,
    userId: 153456,
    name: "Teste01 - Ju",
    clientId: "frontend",
  }, // Teste01
  { companyId: 212, userId: 384, name: "DMRH admin", clientId: "frontend" }, // DMRH admin
  { companyId: 212, userId: 22095, name: "DMRH normal", clientId: "frontend" }, // DMRH normal
  {
    companyId: 58459,
    userId: 178783,
    name: "Redes admin",
    clientId: "frontend",
  }, // Redes admin
  {
    companyId: 58459,
    userId: 189391,
    name: "Redes normal",
    clientId: "frontend",
  }, // Redes normal
  {
    companyId: 65658,
    userId: 213444,
    name: "Simplifica TESTE",
    clientId: "frontend",
  }, // Simplifica TESTE
  {
    companyId: 10352,
    userId: 15045,
    name: "Maxis - Cliente Créditos",
    clientId: "frontend",
  }, // Maxis - Cliente Créditos
  {
    companyId: 67864,
    userId: 225462,
    name: "Hirota - Não tem acesso triagem 2",
    clientId: "frontend",
  }, // Hirota - Não tem acesso triagem 2
  { companyId: 52932, userId: 152092, name: "ITAU", clientId: "frontend" }, // ITAU
  { companyId: 3266, userId: 5678, name: "Oi", clientId: "frontend" }, // Oi
  {
    companyId: 7782,
    userId: 155008,
    name: "Lojas Americanas",
    clientId: "frontend",
  }, // Lojas Americanas
  {
    companyId: 5160,
    userId: 7944,
    name: "Porto Seguro",
    clientId: "frontend",
  }, // Porto Seguro
  {
    companyId: 23760,
    userId: 225943,
    name: "Energia Elétrica",
    clientId: "frontend",
  }, // Porto Seguro
  {
    companyId: 23811,
    userId: 242543,
    name: "Marketdata Solutions Brasil Ltda.",
    clientId: "frontend",
  }, // Porto Seguro
  {
    companyId: 64204,
    userId: 256080,
    name: "Instituto Proa",
    clientId: "frontend",
  }, // Instituto Proa - usuário normal sem permissão de publicar vaga
  {
    companyId: 78936,
    userId: 283928,
    name: "edu123arda - vagas25",
    clientId: "frontend",
  },
];

const ContainerStyled = styled.form`
  max-width: 400px;
  width: 100%;
  margin: 24px auto;
`;

const SelectStyled = styled.select`
  width: 100%;
  margin-bottom: 24px;
`;

const fetcher = (url, method, body) =>
  fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());

const removeLoader = () => {
  const loaderDiv = document.getElementById("vg-loader");
  if (loaderDiv) loaderDiv.parentNode.removeChild(loaderDiv);
};
class Auth extends Component {
  state = {
    select: 0,
  };

  componentDidMount() {
    removeLoader();
  }

  handleOnSubmit = async (e) => {
    const { select } = this.state;
    const body = companies[select];
    e.preventDefault();
    const response = await fetcher("/login/internal_oauth/token", "POST", body);
    const { token, expires_in } = response;

    if (token) {
      setCookie(process.env.REACT_APP_TOKEN, token, expires_in);
      window.location = "/";
    }
  };

  handleChange = (e) => {
    e.preventDefault();

    this.setState({
      select: e.target.value,
    });
  };

  render() {
    const { select } = this.state;
    return (
      <ContainerStyled onSubmit={this.handleOnSubmit}>
        <H1>FakeLogin</H1>
        <SelectStyled
          data-testid="SELECT_COMPANY"
          onChange={this.handleChange}
          value={select}
        >
          {companies.map((company, index) => (
            <option key={company.userId} value={index}>
              {company.name}
            </option>
          ))}
        </SelectStyled>
        <Button type="submit" data-testid="BTN_LOGIN">
          Login
        </Button>
      </ContainerStyled>
    );
  }
}

export default Auth;
