import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import moment from "moment";
import {
  SelectiveProcessList,
  Intro,
  FastLink,
  Graphs,
  Icon,
  GraphV25,
  Link,
} from "../../index";
import SearchBar from "../../molecules/SearchBar";
import "./HomePage.scss";
import { connect } from "react-redux";
import { object } from "prop-types";
import { addVacancyListPageDataLayer } from "../../../utils/dataLayerPush";
import { useBeamer } from "../../../beamer/beamer.hook";
import { useZoho } from "../../../zoho/zoho.hook";
import intl from "react-intl-universal";
import { vpv } from "../../../utils/VirtualPageView";

function HomePage({ panel }) {
  const [acessBar, setAcessBar] = useState("");
  const [zohoHasLoaded, setZohoHasLoaded] = useState(false);
  const [hasDigitalAdmission, setHasDigitalAdmission] = useState(false);

  useBeamer(panel);
  useZoho(panel, setZohoHasLoaded, panel?.cliente.cadastro_vagas25);
  useEffect(() => {
    if (panel && panel.cliente && panel.funcionario && zohoHasLoaded) {
      addVacancyListPageDataLayer({
        companyId: panel.cliente.id || "valor-indisponivel",
        companyName: panel.cliente.nome || "valor-indisponivel",
        userId: panel.funcionario.id || "valor-indisponivel",
      });
      setAcessBar(
        panel.funcionario.permissoes.dir_acesso_banco_curriculos == 2
      );
    }
    if (panel?.feature_flags?.includes("admissao_digital"))
      setHasDigitalAdmission(true);
  }, [panel, zohoHasLoaded]);

  return (
    <div className="vg-home-page">
      <Intro
        type="painel"
        path="painel"
        isVagas25={panel?.cliente.cadastro_vagas25}
      />
      {!!(
        panel &&
        panel?.feature_flags?.includes("pesquisa-simplificada") &&
        acessBar &&
        !panel?.cliente.cadastro_vagas25
      ) && <SearchBar />}
      <div className="spacing" />
      <div className="vg-container">
        {panel?.cliente.cadastro_vagas25 ? (
          <section className="vg-row">
            <div className="vg-col-md-12 vg-col-xs-12">
              <div className="vg-graphs vg-boxes-graphs">
                <div className="vg-col-xs-12 vg-col-md">
                  <div className="vg-graphs-content vg-graphs-sla vg-display-flex">
                    <div>
                      <span className="vg-icon-bg-border">
                        <Icon iconKey="briefcase2" aria-hidden="true" />
                      </span>
                    </div>
                    <div>
                      <h2>
                        {intl.get(
                          "HOME.CONTEUDO.VAGAS_25.ABRIR_PROCESSO.TITULO"
                        )}
                      </h2>
                      <p>
                        {intl.get(
                          "HOME.CONTEUDO.VAGAS_25.ABRIR_PROCESSO.DESCRICAO"
                        )}
                      </p>
                      <div className="vg-content-btns">
                        <Link
                          href="https://suporte-forbusiness.vagas.com.br/portal/pt-br/kb/articles/como-crio-uma-vaga-de-um-novo-cargo"
                          target="_blank"
                          className="vg-btn-outline-light-gray"
                        >
                          {intl.get(
                            "HOME.CONTEUDO.VAGAS_25.ABRIR_PROCESSO.BOTAO"
                          )}
                        </Link>
                        <Link
                          className="vg-btn-success"
                          href="/move-to/divulgar-vagas"
                        >
                          {intl.get("HOME.CONTEUDO.BOTAO_CRIAR_VAGA")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vg-col-xs-12 vg-col-md">
                  <div className="vg-graphs-content vg-graphs-sla vg-display-flex">
                    <div>
                      <span className="vg-icon-bg-border">
                        <Icon iconKey="dollar2" aria-hidden="true" />
                      </span>
                    </div>
                    <div>
                      <h2>
                        {intl.get(
                          "HOME.CONTEUDO.VAGAS_25.CONTROLE_CREDITOS.TITULO"
                        )}
                      </h2>
                      <p>
                        {intl.get(
                          "HOME.CONTEUDO.VAGAS_25.CONTROLE_CREDITOS.DESCRICAO"
                        )}
                      </p>
                      <div className="vg-content-btns vg-ctn-left">
                        <Link
                          className="vg-btn-outline-light-gray"
                          onClick={() => vpv("/funil-compras/creditos-menu")}
                          moveTo="comprar-creditos-vagas"
                        >
                          {intl.get(
                            "HOME.CONTEUDO.VAGAS_25.CONTROLE_CREDITOS.BOTAO"
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vg-col-xs-12 vg-col-md">
                  <div className="vg-graphs-content vg-graphs-sla vg-display-flex vg-card-block">
                    <GraphV25 />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <>
            <section className="vg-row">
              <div className="vg-col-md-12 vg-col-xs-12">
                <FastLink
                  type="painel"
                  path="painel"
                  admissaoDigital={hasDigitalAdmission}
                />
              </div>
            </section>
            <section className="vg-row">
              <h2 className="visually-hidden">
                {intl.get("GRAPHS.SLA.TITLE_SECTION")}
              </h2>
              <div className="vg-col-md-12 vg-col-xs-12">
                {panel ? <Graphs /> : null}
              </div>
            </section>
          </>
        )}
        <section className="vg-row">
          <div className="vg-col-md-12 vg-col-xs-12">
            <SelectiveProcessList
              panel={panel}
              type="home"
              path={`home?apenas_do_funcionario=true&data_criacao_depois_de=${moment()
                .subtract(4, "month")
                .format("YYYY-MM-DD")}`}
              isVagas25={panel?.cliente.cadastro_vagas25}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  panel: state.Painel.painel.data,
  credits: state.Credits.disponiveis,
});

HomePage.propTypes = {
  panel: object.isRequired,
  check: propTypes.any,
  credits: propTypes.number,
};

export default connect(mapStateToProps)(HomePage);
